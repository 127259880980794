import React from "react"
import Layout from "../../../../components/layout"
import Seo from "../../../../components/Seo"
import {
  PrimaryHero,
  HeroCopyBlock,
} from "../../../../components/Heros"
import Spacer from "../../../../components/Spacer"
import { DualImageBlock } from "../../../../components/Grid"

import cx from "classnames"
import styles from "./styles.module.scss"

import poolImg from "./images/pool.png"
import playingPoolImg from "./images/poolgame.png"
import msgIcon from "./images/default.svg"
import bruIcon from "./images/bru.svg"
import pramIcon from "./images/pram.svg"
import eyeIcon from "./images/eye.svg"
import cocktailIcon from "./images/cocktail.svg"
import flightImg from "./images/flight.svg"

const Benefits = () => (
  <Layout JobSection>
    <Seo title="Sorted | Benefits" keywords={[`About us`, `Journey`, `History`, `Culture`]} />
    <PrimaryHero theme="pink">
      <HeroCopyBlock
        title={["Brilliance,", "rewarded."]}
      >
        <p>As if it’s not enough to be part of the revolution that’s transforming the retail industry through clever deliver tech and critical partnerships, here’s a bit of info about our benefits package.</p>
      </HeroCopyBlock>
    </PrimaryHero>

    <Spacer size="medium" sides="topbottom">
      <div className="container-thin">
        <h2 className="text-center text-pink">Because you’re so good.</h2>
        <p className="text-center">We make sure your hard work is recognised and recompensated. We’re very proud to bring such awesome benefits to the people that help us delivery awesomeness—keeping our ranks motivated, happy and healthy.</p>
      </div>
    </Spacer>

    <div className="container">
      <div className={styles.benefitsBlocks}>
        <div className={cx(styles.benefitsBlock, styles.benefitsBlockGrey)}>
          <div>
            <div className={styles.benefitsBlockIcon} />
          </div>
          <h4>Pension</h4>
          <p>We give you a 5% pension pot contribution.</p>
        </div>
        <div className={cx(styles.benefitsBlock, styles.benefitsBlockImg)}>
          <img src={poolImg} alt="people in swimming pool" />
          <div>
            <img src={flightImg} alt="flight" className={styles.benefitsBlockIcon} />
          </div>
          <h4>Holidays</h4>
          <p>Our whopping 40 day holiday entitlement is enough to keep you refreshed.</p>
        </div>
        <div className={cx(styles.benefitsBlock, styles.benefitsBlockPink)}>
          <div>
            <img src={pramIcon} alt="pram" className={styles.benefitsBlockIcon} />
          </div>
          <h4>Childcare</h4>
          <p>An invaluable helping hand for parents through childcare vouchers.</p>
        </div>
      </div>
    </div>

    <DualImageBlock img={playingPoolImg} theme="purple" maxHeight={700} reverse>
      <h3>Other sweeteners.</h3>
      <div className={styles.bulletSection}>
        <div className={styles.bulletItem}>
          <img src={msgIcon} alt="message icon" className={styles.msgIcon}/>
          <p>Well, more savoury than sweet… We’re famous for our monthly Cheesy Tuesdays, where we put on an almighty cheese board for free.</p>
        </div>
        <div className={styles.bulletItem}>
          <img src={cocktailIcon} alt="cocktail icon" className={styles.msgIcon}/>
          <p>Beer Fridge Friday—kicking off the weekend with a tipple or two.</p>
        </div>
        <div className={styles.bulletItem}>
          <img src={eyeIcon} alt="eye icon" className={styles.msgIcon}/>
          <p>Free eye tests. Blinking brilliant, especially when you’re working on a screen all day.</p>
        </div>
        <div className={styles.bulletItem}>
          <img src={bruIcon} alt="bru icon" className={styles.msgIcon}/>
          <p>Breakfast on us. The most important meal of the day; for free, every day.</p>
        </div>
        <div className={styles.bulletItem}>
          <img src={msgIcon} alt="message icon" className={styles.msgIcon}/>
          <p>Pampering on-site. We’ve got our own office salon—with ladies and gents hair treatments at a reduced price.</p>
        </div>
        <div className={styles.bulletItem}>
          <img src={msgIcon} alt="message icon" className={styles.msgIcon}/>
          <p>Get your birthday off work, to help you celebrate.</p>
        </div>
      </div>
    </DualImageBlock>

  </Layout>
)

export default Benefits
